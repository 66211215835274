import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';

// Application
import {
  GetAllianzAccountQuery,
} from '@/modules/my-investment/allianz/allianz-account/application/queries';
import {
  SearchAllianzEarningsReportQuery,
} from '@/modules/my-investment/allianz/allianz-earnings-report/application/queries';

// Domain
import {
  AllianzEarningsReportEntity,
} from '@/modules/my-investment/allianz/allianz-earnings-report/domain/entities/allianz-earnings-report-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class PerformanceViewModel {
  @Inject(TYPES.GET_ALLIANZ_ACCOUNT_QUERY)
  private readonly get_allianz_account_query!: GetAllianzAccountQuery;

  @Inject(TYPES.SEARCH_ALLIANZ_EARNINGS_REPORT_QUERY)
  private readonly search_allianz_earnings_report_query!: SearchAllianzEarningsReportQuery;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  readonly i18n_namespace = 'components.allianz-dashboard.performance';

  private readonly customer_id = sessionStorage.getItem('user_id');

  is_loading = true;

  performance_value = 0.0;

  performance_percentage = 0.0;

  series: Record<string, any> = [];

  last_six_months: Array<string> = [];

  chartOptions = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      parentHeightOffset: 0,
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
    },
    colors: ['#0047BA', '#64D2E0'],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
        columnWidth: '40%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: this.last_six_months,
      labels: {
        style: {
          colors: '#75A4FE',
        },
      },
    },
    yaxis: {
      labels: {
        formatter(value: number) {
          return currencyFormat(value);
        },
      },
    },
    legend: {
      show: false,
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      x: {
        formatter(val: string) {
          const full_name_months: Array<any> = [
            { short_name: 'Ene', long_name: 'Enero' },
            { short_name: 'Feb', long_name: 'Febrero' },
            { short_name: 'Mar', long_name: 'Marzo' },
            { short_name: 'Abr', long_name: 'Abril' },
            { short_name: 'May', long_name: 'Mayo' },
            { short_name: 'Jun', long_name: 'Junio' },
            { short_name: 'Jul', long_name: 'Julio' },
            { short_name: 'Ago', long_name: 'Agosto' },
            { short_name: 'Sep', long_name: 'Septiembre' },
            { short_name: 'Oct', long_name: 'Octubre' },
            { short_name: 'Nov', long_name: 'Noviembre' },
            { short_name: 'Dic', long_name: 'Diciembre' },
          ];
          const month = full_name_months.find((item) => item.short_name === val);
          return month.long_name;
        },
      },
    },
  };

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  get performance_formatted_as_percent() {
    const sign = this.performance_percentage >= 0 ? '+' : '-';
    return `${sign} ${this.performance_percentage} %`;
  }

  loadEarnings = async () => {
    try {
      const earnings = await this.search_allianz_earnings_report_query.execute(this.customer_id);
      if (earnings.length) {
        this.fillData(earnings);
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_position'));
    }
  }

  fillData(allianz_earnings: Array<AllianzEarningsReportEntity>) {
    this.series = [];
    const earning_pesos = allianz_earnings.map(
      (earning) => earning.final_value,
    );
    while (earning_pesos.length < 6) {
      earning_pesos.unshift(0);
    }
    this.performance_value = allianz_earnings[allianz_earnings.length - 1].earnings;
    this.performance_percentage = Number((allianz_earnings[allianz_earnings.length - 1]
      .earnings_yield_yearly).toFixed(2));
    this.series.push({
      name: 'SWS - PESOS',
      data: earning_pesos,
    });
  }

  capitalize = (text: string) => text.charAt(0).toUpperCase() + text.slice(1);

  loadMonthsList = (current_date: Date) => {
    const latest_sixs_months = [];
    let date = null;
    for (let index = 0; index < 6; index += 1) {
      date = new Date(current_date.getFullYear(), current_date.getMonth() - index, 1);
      latest_sixs_months.push(
        this.capitalize(
          new Intl.DateTimeFormat('es-MX', { month: 'short' }).format(date),
        ),
      );
    }
    this.chartOptions.xaxis.categories = latest_sixs_months.reverse();
  }

  initialize = async () => {
    this.loadMonthsList(new Date());
    await this.loadEarnings();
    this.is_loading = false;
  }
}
