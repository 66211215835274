



















































import { Component, Vue } from 'vue-property-decorator';
import { currencyFormat } from '@/vue-app/utils/currency';
import PerformanceViewModel from '../../view-models/allianz-dashboard/performance-view-model';
import LoadingDataCard from '@/vue-app/components/home-dashboard/LoadingDataCard.vue';

@Component({
  name: 'Performance',
  components: { LoadingDataCard },
  filters: { currencyFormat },
})
export default class Performance extends Vue {
  performance_view_model = Vue.observable(new PerformanceViewModel());

  created() {
    this.performance_view_model.initialize();
  }
}
